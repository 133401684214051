<script lang="ts" setup>
const { open } = useAppModals();
const router = useRouter();

const { t } = useT();
const pageData = [
	{
		icon: "oportunity-quest",
		type: "quest",
		class: "quest",
		title: t("Missions")
	},
	{
		icon: "oportunity-vip",
		type: "vip",
		class: "vip",
		title: t("VIP Club")
	},
	{
		icon: "oportunity-games",
		type: "games",
		class: "games",
		title: t("Games")
	},
	{
		icon: "oportunity-promotions",
		type: "promotions",
		class: "promotions",
		title: t("Promotions")
	}
];

const handleClick = (opportunityType: string) => {
	if (["quest", "vip", "promotions"].includes(opportunityType)) {
		open("LazyOModalSignup");
		return;
	}
	if (opportunityType === "games") {
		router.push("/issues/all-games/");
	}
};
</script>

<template>
	<div class="oportunity">
		<div
			v-for="(item, i) in pageData"
			:key="i"
			class="oportunity__item"
			:class="item.class"
			@click="handleClick(item.type)"
		>
			<NuxtImg src="/nuxt-img/oportunity/star-shine.png" format="webp" :alt="item.type" class="star star-first" />
			<NuxtImg src="/nuxt-img/oportunity/star-shine.png" format="webp" :alt="item.type" class="star star-second" />
			<NuxtImg
				:src="`/nuxt-img/oportunity/${item.icon}.png`"
				format="webp"
				:alt="item.type"
				width="280"
				height="280"
				class="oportunity__icon"
			/>
			<AText variant="tlaquepaque" class="text-tlalnepantla" :modifiers="['bold']">
				<p class="oportunity__title">{{ item.title }}</p>
			</AText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@keyframes jumping {
	25% {
		opacity: 0;
	}
	50% {
		transform: scale(1.3);
		opacity: 1;
	}
	75% {
		opacity: 0.7;
	}
}
.oportunity {
	position: relative;
	z-index: 3;
	width: 1192px;
	max-width: calc(100% - 32px);
	margin: gutter(-3) auto 0;
	display: flex;
	justify-content: space-between;
	gap: gutter(4);

	@include media-breakpoint-down(lg) {
		gap: gutter(2);
	}

	@include media-breakpoint-down(md) {
		margin: gutter(-1) auto 0;
		flex-wrap: wrap;
	}

	&__item {
		position: relative;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		height: 320px;
		border-radius: 20px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50%;
		padding-bottom: gutter(5.5);

		&:hover {
			cursor: pointer;
		}

		.star {
			position: absolute;

			&-first {
				width: 24px;
				height: 24px;
				animation: jumping 2s linear infinite;

				@include media-breakpoint-down(md) {
					width: 12px;
					height: 12px;
				}
			}

			&-second {
				width: 16px;
				height: 16px;
				animation: jumping 3s ease-in-out 2s infinite;

				@include media-breakpoint-down(md) {
					width: 8px;
					height: 8px;
				}
			}
		}

		@include media-breakpoint-down(md) {
			border-radius: 12px;
			flex: none;
			width: calc(50% - 8px);
			padding: gutter(2.5) gutter(2) gutter(1);
			height: 114px;
		}

		&.quest {
			background: var(--goerlitz);

			.star-first {
				top: 119px;
				left: 27px;

				@include media-breakpoint-down(md) {
					top: 40px;
					left: 19%;
				}
			}

			.star-second {
				top: 160px;
				right: 70px;

				@include media-breakpoint-down(md) {
					top: 62px;
					right: 32%;
				}
			}
		}

		&.vip {
			background: var(--guliston);

			.star-first {
				top: 15px;
				right: 40px;

				@include media-breakpoint-down(md) {
					right: 25%;
					top: 5px;
				}
			}

			.star-second {
				top: 160px;
				left: 85px;

				@include media-breakpoint-down(md) {
					left: 15%;
					top: 50px;
				}
			}
		}

		&.games {
			background: var(--gadwal);

			.star-first {
				top: 100px;
				left: 20px;

				@include media-breakpoint-down(md) {
					left: 17%;
					top: 40px;
				}
			}

			.star-second {
				top: 172px;
				right: 97px;

				@include media-breakpoint-down(md) {
					right: 40%;
					top: 70px;
				}
			}
		}

		&.promotions {
			background: var(--guiren);

			.star-first {
				font-size: 28px;
				top: 170px;
				left: 45px;

				@include media-breakpoint-down(md) {
					font-size: 22px;
					left: 23%;
					top: 70px;
				}
			}

			.star-second {
				top: 40px;
				right: 30px;

				@include media-breakpoint-down(md) {
					right: 24%;
					top: 52px;
				}
			}
		}
	}

	&__icon {
		position: absolute;
		max-width: 100%;
		height: auto;
		top: 0;
		left: 0;
		right: 0;
		margin: auto;

		@include media-breakpoint-down(md) {
			height: 115px;
			width: auto;
		}
	}

	&__title {
		color: var(--clara);
	}
}
</style>
